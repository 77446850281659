import React from 'react'
import { connect } from 'react-redux'
import { HorizField } from 'components'
import { InfoBoxContainer, If, Modal } from 'simple-core-ui'
import ACT from 'budgets/actions'
import { IoIosInformationCircle } from 'react-icons/io'
import BudgetRequestForm from 'budgets/forms/containers/BudgetRequestForm'
import { validateBudgetRequest } from 'budgets/forms/validators'

@connect(({ budgets }, ownProps) => {
  const {
    matterId,
    matterName,
    vendorId,
    vendorName,
    createForm,
    vendorsWithBudgets = []
  } = budgets

  return {
    matterId: ownProps.matterId ?? matterId,
    matterName: ownProps.matterName ?? matterName,
    vendorId: ownProps.vendorId ?? vendorId,
    vendorName: ownProps.vendorName ?? vendorName,
    vendorsWithBudgets: ownProps.vendorsWithBudgets ?? vendorsWithBudgets,
    createForm
  }
})
class CreateBudgetRequestModal extends React.Component {
  submitBudgetRequest = () => {
    const { matterId, vendorId, createForm, submitCb } = this.props
    const { isError, errors } = validateBudgetRequest(createForm)

    if (isError) {
      this.props.dispatch({
        type: ACT.UPDATE_BUDGET_REQUEST_FORM,
        payload: {
          errors
        }
      })
    } else {
      submitCb({
        ...createForm,
        matterId,
        vendorId,
        requested: true
      })
    }
  }

  render() {
    const {
      matterName,
      vendorName,
      vendorId,
      matterId,
      createForm,
      vendorsWithBudgets,
      cancelCb,
      isVisible
    } = this.props
    const errors = createForm?.errors

    const hasBudgets = vendorsWithBudgets.includes(vendorId)
    const activeMessage = `${vendorName} already has an active budget request for this matter`

    return (
      <Modal
        title="Create Budget Request"
        confirmText="Submit"
        cancelText="Cancel"
        size="md"
        content={
          <section>
            <If condition={hasBudgets}>
              <InfoBoxContainer
                message={activeMessage}
                icon={<IoIosInformationCircle />}
                style={{ margin: 12 }}
              />
            </If>
            <HorizField label="Assigned Vendor">
              <span>{vendorName}</span>
            </HorizField>

            <HorizField label="Matter">{matterName}</HorizField>

            <hr />
            <BudgetRequestForm vendorId={vendorId} matterId={matterId} errors={errors} />
          </section>
        }
        isVisible={isVisible}
        confirmCb={this.submitBudgetRequest}
        cancelCb={cancelCb}
        wrapperStyles={{
          width: '600px'
        }}
      />
    )
  }
}

export default CreateBudgetRequestModal
