import { MouseEvent, ReactNode } from 'react'
import { FaTimes, FaChevronDown } from 'react-icons/fa'
import s from './SelectCustomInput.scss'
import noop from 'lodash/noop'
import cn from 'classnames'

interface Props {
  value: string | null | 0 | false | undefined | ReactNode
  placeholder?: string
  onClick: () => void
  onClear?: (e: MouseEvent) => void
  isDisabled?: boolean
  clearable?: boolean
}

const SelectCustomInput = ({
  value,
  placeholder = 'Select...',
  onClick,
  onClear,
  isDisabled,
  clearable = true
}: Props) => {
  return (
    <div
      className={cn(s.header, {
        [s.disabled]: isDisabled
      })}
      onClick={isDisabled ? noop : onClick}
      data-testid="select-custom-input"
    >
      <div className={s.label}>{value ? value : placeholder}</div>
      <div className={s.controlsContainer}>
        {!!value && onClear && (
          <FaTimes className={s.clearIcon} onClick={isDisabled || !clearable ? noop : onClear} />
        )}
        <div className={s.expandIconBox}>
          <FaChevronDown className={s.expandIcon} />
        </div>
      </div>
    </div>
  )
}

export default SelectCustomInput
