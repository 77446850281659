import { Tooltip } from 'simple-core-ui'
import cn from 'classnames'
import s from './Field.scss'

const HorizField = ({
  label,
  hasError,
  isRequired,
  children,
  tooltip,
  className = '',
  labelClassName = ''
}) => (
  <section className={cn(s.horizContainer, className)} data-testid={`horiz-field-${label}`}>
    <span className={cn(s.horizSpan, labelClassName, hasError && s.error)}>
      {label}
      {isRequired && (
        <span className={s.asterick} title={'required'}>
          {' * '}
        </span>
      )}
      {tooltip && <Tooltip triggerClassName={s.tooltipTrigger} content={tooltip} />}
    </span>
    <span
      className={s.horizSpan}
      data-testid={`field_${label
        .toLowerCase()
        .split(' ')
        .join('_')}`}
    >
      {children}
    </span>
  </section>
)

export default HorizField
