import { FaChevronRight } from 'react-icons/fa'
import s from './Breadcrumbs.scss'
import cn from 'classnames'

interface Props {
  items: Array<{ title: string; clickCb?: () => void; redirectTo?: string }>
}

const Breadcrumbs = ({ items }: Props) => (
  <ul className={s.breadcrumbItems}>
    {items.map((item, i) => (
      <li key={i} className={cn(s.listItem, s.clickable, { [s.vendorName]: i })}>
        <a onClick={item.clickCb} href={item.redirectTo}>
          {item.title}
        </a>
        {!i && <FaChevronRight />}
      </li>
    ))}
  </ul>
)

export default Breadcrumbs
