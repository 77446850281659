import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import s from './ActionsPopover.scss'
import { TimekeeperRow } from 'timekeepers/types'

interface Props {
  timekeeper: TimekeeperRow
  copyTk: (row: TimekeeperRow) => void
  downloadTk: (row: TimekeeperRow) => void
  showDownload: boolean
}

const ActionsPopover = ({ timekeeper, copyTk, downloadTk, showDownload }: Props) => {
  const actions = [
    {
      name: 'VIEW',
      text: 'View Details',
      onClick() {
        return
      }
    },
    ...(showDownload
      ? [
          {
            name: 'DOWNLOAD',
            text: 'Download',
            onClick() {
              return
            }
          }
        ]
      : [])
  ]

  return (
    <Popover
      testId={`item-${timekeeper.timekeeper_file_id}`}
      trigger={
        <HoverAction
          hasNewDesign
          tip="More"
          icon={<IoIosMore />}
          size="small"
          className={s.hoverAction}
        />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
