import {
  AllRatesAPI,
  CompletedBatchesAPI,
  PendingBatchesAPI,
  TimekeeperListAPI,
  TimekeeperRow
} from './types'
import shortid from 'shortid'

type TimekeeperAPI = PendingBatchesAPI | CompletedBatchesAPI | AllRatesAPI | TimekeeperListAPI

export const serializeTimekeepers = (
  rows: TimekeeperAPI[],
  isRatesView: boolean,
  selectedTab: string,
  isBatchDetailsView: boolean
): TimekeeperRow[] =>
  rows.map(row => {
    //@ts-expect-error
    const baseRow: Partial<TimekeeperRow> = {
      ...row,
      id: 'id' in row ? row.id : (row as PendingBatchesAPI | CompletedBatchesAPI).timekeeper_file_id
    }

    if (!isRatesView) {
      Object.assign(baseRow, {
        timekeeper: `${(row as TimekeeperListAPI).first_name} ${
          (row as TimekeeperListAPI).last_name
        }`,
        diversity: `${(row as TimekeeperListAPI).diversity}/5 filled`
      })
    }

    if (isRatesView && selectedTab !== 'all') {
      Object.assign(baseRow, {
        full_name:
          (row as PendingBatchesAPI | CompletedBatchesAPI).full_name === '-' ||
          !(row as PendingBatchesAPI | CompletedBatchesAPI).full_name
            ? ''
            : (row as PendingBatchesAPI | CompletedBatchesAPI).full_name
      })
    }

    if (isRatesView && selectedTab === 'completed' && !isBatchDetailsView) {
      Object.assign(baseRow, {
        approver_list: (row as CompletedBatchesAPI).approver_list
          .filter(approver => approver !== ' ')
          .map(approver => ({
            label: approver as string,
            id: shortid.generate()
          }))
      })
    }

    if ((isRatesView && selectedTab === 'all') || isBatchDetailsView) {
      Object.assign(baseRow, {
        timekeeper: `${(row as AllRatesAPI).timekeeper.first_name} ${
          (row as AllRatesAPI).timekeeper.last_name
        }`,
        timekeeper_id: (row as AllRatesAPI).timekeeper.timekeeper_id,
        classification: (row as AllRatesAPI).timekeeper.classification || '---',
        vendor_name: (row as AllRatesAPI).timekeeper.vendor_name,
        vendor_id: (row as AllRatesAPI).timekeeper.vendor_id,
        status: (row as AllRatesAPI).status === 'None' ? 'pending' : (row as AllRatesAPI).status
      })
    }

    return baseRow as TimekeeperRow
  })
