import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { If, CharLimitInput, useLoading } from 'simple-core-ui'
import { HorizField, Input } from 'components'
import Select from 'react-select'
import MonthPickerInput from 'react-month-picker-input'
import { ScrollSelect, ReactDatePicker } from 'containers'
import { UsersCheckboxSelect } from 'common/Selects'
import { makeGetRequest } from 'utils/api'
import { toUsers } from '../../serializers'
import moment from 'moment'
import ACT from 'budgets/actions'
import get from 'lodash/get'
import s from '../styles/BudgetRequestForm.scss'

const MAX_CHAR_LENGTH = 250

const refreshPeriods = [
  { label: 'None', value: 'None' },
  { label: 'Annually', value: 'annually' },
  { label: 'Biannually', value: 'biannually' },
  { label: 'Quarterly', value: 'quarterly' },
  { label: 'Monthly', value: 'monthly' }
]

const monthPickerOptions = {
  dateFormat: { default: 'MM/YYYY' },
  monthFormat: 'short',
  monthNames: {
    default: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
  }
}

const REFRESH_TOOLTIP_TEXT =
  'Select frequency for sending automated requests to the vendor to keep your budget up to date.'

const BudgetRequestForm = ({ vendorId, matterId, isLegacyBudget, scope, ...props }) => {
  const [, withLoadingLocks] = useLoading()
  const dispatch = useDispatch()
  const { phaseSets, budgetTypes, createForm } = useSelector(({ budgets }) => budgets)
  const budgetTypeOptions = budgetTypes.map(type => ({
    value: type.id,
    label: type.name,
    id: type.id
  }))

  const HorizFieldWrapper = useMemo(() => {
    return isLegacyBudget
      ? HorizField
      : props => <HorizField {...props} className={s.noFlex} labelClassName={s.label} />
  }, [isLegacyBudget])

  const matterName = scope === 'matter' ? props.scopeName : null

  const {
    budgetName,
    budgetDescription,
    budgetType,
    budgetYear,
    internalApprovers,
    collaborators,
    phaseSet,
    errors,
    refreshPeriod,
    amount,
    budgetDueDate
  } = createForm

  const populateInternalApprovers = leads => {
    updateForm('internalApprovers', toUsers(leads))
  }

  const fetchMatterAndVendorLeads = async () => {
    try {
      const { results } = await withLoadingLocks(
        makeGetRequest('/manage/contacts/lead-roles/', {
          params: { m_id: matterId, v_id: vendorId }
        })
      )
      populateInternalApprovers(results)
    } catch (error) {
      dispatch({ type: 'API_ERROR', error })
    }
  }

  useEffect(() => {
    if (!isLegacyBudget) {
      fetchMatterAndVendorLeads()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLegacyBudget])

  const updateForm = (key, value) => {
    dispatch({
      type: ACT.UPDATE_BUDGET_REQUEST_FORM,
      payload: {
        [key]: value
      }
    })
  }

  return (
    <>
      <HorizFieldWrapper label="Budget Type" hasError={errors?.budgetType} isRequired>
        <Select
          aria-label="Budget Type"
          id={`testid_${vendorId}_budget_type_select`}
          value={budgetType}
          options={budgetTypeOptions}
          onChange={op => updateForm('budgetType', op)}
        />
      </HorizFieldWrapper>

      {matterName && isLegacyBudget && <HorizField label="Matter">{matterName}</HorizField>}

      <If condition={!isLegacyBudget}>
        <HorizFieldWrapper label="Budget Name" hasError={errors?.budgetName} isRequired>
          <Input
            type="text"
            value={budgetName}
            id={`testid_${vendorId}_budget_name_input`}
            onChange={value => updateForm('budgetName', value)}
            className={s.textInput}
          />
        </HorizFieldWrapper>
        <HorizFieldWrapper label="Description" hasError={errors?.budgetDescription}>
          <CharLimitInput
            type="textarea"
            rows={4}
            value={budgetDescription || ''}
            onChangeCb={e => updateForm('budgetDescription', e.target.value)}
            maxLength={MAX_CHAR_LENGTH}
            dynamicCharCalculation
            style={{ borderRadius: 4 }}
            customErrorMessage="Maximum character limit reached"
            className={s.textInput}
            cssClass={budgetDescription?.length > MAX_CHAR_LENGTH ? s.errorClass : ''}
            customErrorStyle={{
              color: '#C7596D'
            }}
          />
        </HorizFieldWrapper>
      </If>

      <If condition={budgetType && budgetType.value !== 4}>
        <HorizFieldWrapper label="Start Date" hasError={errors?.budgetStartDate} isRequired>
          <MonthPickerInput
            year={parseInt(moment().format('YYYY'))}
            inputProps={{
              id: `testid_${vendorId}_budget_start_date_input`,
              className: s.textInput
            }}
            i18n={monthPickerOptions}
            closeOnSelect
            onChange={(maskedValue, selectedYear, selectedMonth) => {
              const date = moment()
                .month(selectedMonth)
                .year(selectedYear)
                .format('MM-YYYY')
              updateForm('budgetStartDate', date)
            }}
          />
        </HorizFieldWrapper>
        <HorizFieldWrapper label="End Date" hasError={errors?.budgetEndDate} isRequired>
          <MonthPickerInput
            year={parseInt(moment().format('YYYY'))}
            inputProps={{
              id: `testid_${vendorId}_budget_end_date_input`,
              className: s.textInput
            }}
            i18n={monthPickerOptions}
            closeOnSelect
            onChange={(maskedValue, selectedYear, selectedMonth) => {
              const date = moment()
                .month(selectedMonth)
                .year(selectedYear)
                .format('MM-YYYY')
              updateForm('budgetEndDate', date)
            }}
          />
        </HorizFieldWrapper>
      </If>

      <If condition={budgetType && budgetType.value === 4}>
        <HorizFieldWrapper label="Quarter Year" hasError={errors?.budgetYear} isRequired>
          <Input
            ariaLabel="Quarter Year"
            className={s.textInput}
            id={`testid_${vendorId}_budget_quarter_year`}
            type="text"
            value={budgetYear}
            onChange={value => updateForm('budgetYear', value)}
          />
        </HorizFieldWrapper>
      </If>

      <If condition={budgetType && budgetType.value === 1}>
        <HorizFieldWrapper label="Phase Set" hasError={errors?.phaseSet} isRequired>
          <Select
            aria-label="Phase Set"
            id={`testid_${vendorId}_phase_set_select`}
            value={phaseSet}
            options={phaseSets}
            onChange={op => updateForm('phaseSet', op)}
          />
        </HorizFieldWrapper>
      </If>

      <If condition={!isLegacyBudget}>
        <HorizFieldWrapper label="Due Date" hasError={errors?.budgetDueDate}>
          <ReactDatePicker
            value={budgetDueDate ? moment(budgetDueDate).format('L') : ''}
            className={s.dueDate}
            onChange={d => {
              const isDateValid = moment(d).isValid()
              updateForm('budgetDueDate', isDateValid ? moment(d).format('YYYY-MM-DD') : d)
            }}
            onInputChange={e => {
              updateForm('budgetDueDateInput', e.target.value)
            }}
          />
        </HorizFieldWrapper>

        <HorizFieldWrapper label="Refresh Cycle" tooltip={REFRESH_TOOLTIP_TEXT}>
          <Select
            aria-label="Refresh Cycle"
            value={refreshPeriod}
            options={refreshPeriods}
            onChange={op => updateForm('refreshPeriod', op)}
          />
        </HorizFieldWrapper>

        <HorizFieldWrapper label="Internal Approvers">
          <UsersCheckboxSelect
            showEmail
            value={internalApprovers || []}
            requestParams={{ matterId: matterId, canEdit: true, active: true }}
            updateSelectedUsers={op => updateForm('internalApprovers', op)}
            serializer={toUsers}
            disallowRemoval
            clearable={false}
          />
        </HorizFieldWrapper>

        <HorizFieldWrapper
          label="CounselGO Collaborators"
          hasError={errors?.collaborators}
          isRequired
        >
          <UsersCheckboxSelect
            showEmail
            scope="vendor"
            value={collaborators || []}
            updateSelectedUsers={op => updateForm('collaborators', op)}
            serializer={toUsers}
            requestParams={{ vendorId: vendorId, vrn: '_cg', active: true }}
          />
        </HorizFieldWrapper>
      </If>

      <If condition={isLegacyBudget}>
        <If condition={budgetType && budgetType.value === 3 && !matterName}>
          <HorizField label="Matter" hasError={errors?.matterId} isRequired>
            <ScrollSelect
              ariaLabel="Matter"
              id={`testid_${vendorId}_budget_matter_select`}
              url="/manage/matters/select2_json/?matter_status=open"
              onChange={m => updateForm('matterId', get(m, 'value', null))}
            />
          </HorizField>
        </If>
        <HorizField label="Amount" hasError={errors?.amount} isRequired>
          <Input
            ariaLabel="Budget Amount"
            type="number"
            value={amount}
            id={`testid_${vendorId}_budget_amount_input`}
            onChange={value => updateForm('amount', value)}
            style={{ marginRight: 4 }}
          />
        </HorizField>
      </If>
    </>
  )
}

export default BudgetRequestForm
